import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { SEO } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PrevArrow from 'images/shared/PrevArrow';

const OnboardingPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        developerImage: file(relativePath: { eq: "admin/onboarding/onboarding-developers.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 50)
          }
        }
        emailImage: file(relativePath: { eq: "admin/onboarding/onboarding-emails.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 50)
          }
        }
        designerImage: file(relativePath: { eq: "admin/onboarding/onboarding-design.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 50)
          }
        }
      }
    `
  );

  const guides = [
    {
      id: 1,
      category: `admin`,
      title: `Email Setup Guide`,
      summary: `The boring stuff ...`,
      link: '/emails/',
      image: data.emailImage
    },
    {
      id: 2,
      category: `dev`,
      title: `Onboarding for Developers`,
      summary: `Eat, sleep, code, repeat ...`,
      link: '/developers/',
      image: data.developerImage
    },
    {
      id: 3,
      category: `design`,
      title: `Onboarding for Designers`,
      summary: `Pencil sketch or Photoshop?`,
      link: '/designers/',
      image: data.designerImage
    }
  ];

  return (
    <>
      <SEO pageSpecificTitle="Onboarding for Developers" />
      <Wrapper>
        <InnerWrapper>
          <BackButton to="/admin/">
            <PrevArrow width="1.688rem" />
          </BackButton>
          <h1>
            Onboarding <span>- Guides</span>
          </h1>
          <Cards>
            {guides.map((guide) => (
              <Card key={guide.id} to={`/admin/onboarding${guide.link}`}>
                <GatsbyImage image={getImage(guide.image)} alt={guide.title} />
                <div>
                  <h5>{guide.category}</h5>
                  <h3>{guide.title}</h3>
                  <p>{guide.summary}</p>
                  <SeeMore type="button">Read More</SeeMore>
                </div>
              </Card>
            ))}
          </Cards>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const BackButton = styled(Link)`
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: -0.75em;
  margin-bottom: 2rem;
  transition: 150ms ease-in-out;
  width: 3rem;

  &:hover {
    background-color: #000;
  }

  &:not(:hover) {
    background-color: var(--ax-gold);
  }

  &:active {
    background-color: #c1beac;
  }
`;

const SeeMore = styled.button`
  width: auto;
  outline: none;
  border: none;
  background: none;
  display: inline-block;
  border-bottom: 3px solid black;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: 0.75rem;
  padding-left: 0;
  position: absolute;
  bottom: 1rem;
  transition: 0.375s;
  transform: translateY(20px);
  width: 100%;
  opacity: 0;
`;

const Card = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-items: flex-start;
  transition: 0.375s;
  text-decoration: none;
  padding-bottom: 3rem;
  .gatsby-image-wrapper {
    width: 100%;
    max-height: 200px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    transition-duration: 0.25s;
    img {
      max-width: 100%;
    }
  }
  div {
    width: 100%;
    padding-bottom: 2rem;
    padding-right: 2rem;
    color: black;
    h5 {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 400;
      color: black;
      margin-bottom:.5em;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 0.75em;
      font-weight: 100;
      transition: font-weight 0.25s ease-in-out;
    }
    p {
      font-size: 1rem;
      letter-spacing: 0.001em;
      line-height: 1.4em;
      margin-bottom: 1em;
    }
  }
  &:hover {
    h3 {
      font-weight: 700;
      color: var(--ax-gold);
    }

    button{
      color: var(--ax-gold);
      border-color:  color: var(--ax-gold);
      opacity: 1;
  transform: translateY(0px);


    }
    .gatsby-image-wrapper {
      transform: scale(1.01);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    }
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1150px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const InnerWrapper = styled.div`
  grid-column: 2 / 3;
  width: 100%;

  @media screen and (min-width: 48rem) {
    margin-top: -4.825rem;
  }

  @media screen and (min-width: 68.75rem) {
    margin: -3.5rem 0 9.1rem;
  }
`;

const Wrapper = styled.section`
  padding-top: 7.1rem;
  position: relative;
  h1 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1em;
    text-transform: capitalize;
    vertical-align: middle;
    margin-bottom: 1em;
    span {
      font-size: 0.5em;
      color: var(--ax-gold);
      font-weight: 300;
    }
  }

  @media screen and (min-width: 48rem) {
    padding-top: 11.9rem;
    min-height: auto;
    h1 {
      font-size: 6rem;
      font-weight: 900;
      line-height: 1.219em;
    }
  }
`;

export default OnboardingPage;
